<template>
  <span v-tooltip="$t(exteriorFull)" class="ext">
    {{ $t(exterior) }}
  </span>
</template>

<script lang="ts" setup>
import {computed} from 'vue'

const props = defineProps<{
  name: string
}>()

const exterior = computed(() => {
  if (/factory new/i.test(props.name)) return 'FN'
  if (/minimal wear/i.test(props.name)) return 'MW'
  if (/field-tested/i.test(props.name)) return 'FT'
  if (/well-worn/i.test(props.name)) return 'WW'
  if (/battle-scarred/i.test(props.name)) return 'BS'
  if (/not painted/i.test(props.name)) return 'NP'

  return ''
})
const exteriorFull = computed(() => {
  if (/factory new/i.test(props.name)) return 'Factory New'
  if (/minimal wear/i.test(props.name)) return 'Minimal Wear'
  if (/field-tested/i.test(props.name)) return 'Field-Tested'
  if (/well-worn/i.test(props.name)) return 'Well-Worn'
  if (/battle-scarred/i.test(props.name)) return 'Battle-Scarred'
  if (/not painted/i.test(props.name)) return 'Not Painted'

  return ''
})
</script>
<style lang="scss" scoped>
.ext {
  position: relative;

  //&:hover:deep(.tooltip-container) {
  //  display: block;
  //  top: -50%;
  //}
}
</style>
